import Login from '../pages/login/Loadable'
import Register from '../pages/register/Loadable'
import ResetPassword from '../pages/resetPassword/Loadable'
import UserActivation from '../pages/userActivation/Loadable'
import ResetPasswordConfirmation from '../pages/resetPasswordConfirmation/Loadable'
import Home from '../pages/home/Loadable'
import Feed from 'pages/feed/Feed'
import Account from '../pages/account/Loadable'
import Messages from '../pages/account/messages/Messages'
import Compose from '../pages/account/messages/Compose'
import Notifications from '../pages/notifications/Notifications.js'
import Jobs from '../pages/jobs/Loadable'
import Error from '../pages/error/Loadable'
import Page from '../pages/page/Loadable'
import ProfileEdit from '../pages/account/ProfileEdit'
import PharmacyPage from '../pages/pharmacies/PharmacyPage'
import AgencyPage from '../pages/page/agencies/AgencyPage.jsx'
import CreatePharmacyReview from '../pages/pharmacies/review/CreatePharmacyReview'
import LocumPharmacistReview from '../pages/pharmacies/review/locum/LocumPharmacistReview'
import SuccessLocumReview from '../pages/pharmacies/review/locum/SuccessLocumReview'
import LocumMoreQuestions from '../pages/pharmacies/review/locum/LocumMoreQuestions'
import PharmacistReview from '../pages/pharmacies/review/pharmacist/PharmacistReview'
import SuccessPharmacistReview from '../pages/pharmacies/review/pharmacist/SuccessPharmacistReview'
import PharmacistMoreQuestions from '../pages/pharmacies/review/pharmacist/PharmacistMoreQuestions'
import PharmacySearchResults from 'pages/pharmacies/PharmacySearchResults'
import AgencySearchResults from 'pages/page/agencies/AgencySearchResults'
import UserSearchResults from 'pages/page/users/UserSearchResults'
import PharmacyLocumRateData from 'pages/dataInsight/PharmacyLocumRateData'
import SinglePost from 'pages/feed/SinglePost'
import Dashboard from 'pages/dataInsight/Dashboard'
import ClinicalQuestions from 'pages/clinicalQuestions/ClinicalQuestions'
import ClinicalQuestionPage from 'pages/clinicalQuestions/ClinicalQuestionPage'
import AddClinicalQuestionPage from 'pages/clinicalQuestions/AddClinicalQuestionPage'

const routes = [
  {
    path: '/home',
    component: Home,
    isPrivate: false,
    navbarMode: 'transparent',
  },
  {
    path: '/login',
    component: Login,
    isPrivate: false,
    title: 'Login',
  },
  {
    path: '/register',
    component: Register,
    isPrivate: false,
    title: 'Register',
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    isPrivate: false,
    title: 'Reset Password',
  },
  {
    path: '/user/activate/:uid/:token',
    component: UserActivation,
    isPrivate: false,
    title: 'User Activation',
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    component: ResetPasswordConfirmation,
    isPrivate: false,
    title: 'Reset Password Confirmation',
  },
  {
    path: '/',
    component: Feed,
    isPrivate: true,
    title: 'Syrle Feed',
  },
  {
    path: '/post/:id',
    component: SinglePost,
    isPrivate: true,
    title: 'Post',
  },
  {
    path: '/pharmacies/',
    component: PharmacySearchResults,
    isPrivate: true,
    title: 'Pharmacies',
  },
  {
    path: '/data-insight/',
    component: Dashboard,
    isPrivate: true,
    title: 'Data Insight dashboard',
  },
  {
    path: '/data-insight/locum-rate-choropleth/',
    component: PharmacyLocumRateData,
    isPrivate: true,
    title: 'Data Insight - Locum Rate Choropleth',
  },
  {
    path: '/agencies/',
    component: AgencySearchResults,
    isPrivate: true,
    title: 'Agencies',
  },
  {
    path: '/users/',
    component: UserSearchResults,
    isPrivate: true,
    title: 'Users',
  },
  {
    path: '/account/',
    component: Account,
    isPrivate: true,
    title: 'My Account',
  },
  {
    path: '/messages/',
    component: Messages,
    isPrivate: true,
    title: 'Messages',
  },
  {
    path: '/messages/send-message/',
    component: Compose,
    isPrivate: true,
    title: 'Compose',
  },
  {
    path: '/account/profile-edit/',
    component: ProfileEdit,
    isPrivate: true,
    title: 'Profile Edit',
  },
  {
    path: '/account/:slug',
    component: Account,
    isPrivate: true,
    title: 'My Account',
  },
  {
    path: '/notifications/',
    component: Notifications,
    isPrivate: true,
    title: 'Notifications',
  },
  {
    path: '/jobs',
    component: Jobs,
    isPrivate: true,
    title: 'Jobs',
  },
  {
    path: '/clinical-questions/',
    component: ClinicalQuestions,
    isPrivate: true,
  },
  {
    path: '/clinical-questions/add/',
    component: AddClinicalQuestionPage,
    isPrivate: true,
  },
  {
    path: '/clinical-questions/:question_id',
    component: ClinicalQuestionPage,
    isPrivate: true,
  },
  {
    path: '/page/:slug',
    component: Page,
    isPrivate: false,
  },
  {
    path: '/page/agency/:slug',
    component: AgencyPage,
    isPrivate: true,
  },
  {
    path: '/page/pharmacy/:slug',
    component: PharmacyPage,
    isPrivate: true,
  },
  {
    path: '/page/pharmacy/:slug/create-review/',
    component: CreatePharmacyReview,
    isPrivate: true,
  },
  {
    path: '/page/pharmacy/:slug/create-review/locum-pharmacist/',
    component: LocumPharmacistReview,
    isPrivate: true,
  },
  {
    path:
      '/page/pharmacy/:slug/create-review/locum-pharmacist/:review_id/success/',
    component: SuccessLocumReview,
    isPrivate: true,
  },
  {
    path:
      '/page/pharmacy/:slug/create-review/locum-pharmacist/:review_id/more-questions/',
    component: LocumMoreQuestions,
    isPrivate: true,
  },
  {
    path: '/page/pharmacy/:slug/create-review/pharmacist/',
    component: PharmacistReview,
    isPrivate: true,
  },
  {
    path: '/page/pharmacy/:slug/create-review/pharmacist/:review_id/success/',
    component: SuccessPharmacistReview,
    isPrivate: true,
  },
  {
    path:
      '/page/pharmacy/:slug/create-review/pharmacist/:review_id/more-questions/',
    component: PharmacistMoreQuestions,
    isPrivate: true,
  },
  {
    path: '/*',
    component: Error,
    isPrivate: false,
    title: '404',
  },
]

export default routes
